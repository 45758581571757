<template>
    <v-container fluid>
        <v-form @submit.prevent="getSecurityExits()">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'security'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('departure_from_terminal') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12" v-if="false">
                                <ValidationProvider ref="container" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="container" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-focus-field-horizontal"
                                                  :label="$t('container')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0"  cols="12" sm="6">
                                <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFrom" name="date_from" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFrom" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_from')"
                                                          prepend-icon="mdi-calendar" color="primary" readonly v-on="on"
                                                          clearable
                                                          @click:clear="dateFrom = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang"
                                                   @change="dateMenuFrom = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0"  cols="12" sm="6">
                                <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateTo" name="date_to" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateTo" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_to')"
                                                          prepend-icon="mdi-calendar" color="primary" readonly v-on="on"
                                                          clearable
                                                          @click:clear="dateTo = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().subtract(100, 'y').format('YYYY-MM-DD')"
                                                   :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                   @change="dateMenuTo = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>

                            <v-col cols="12" sm="12" md="12"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="security_exits" :options.sync="options"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.date_inspection="{ item }">
                        {{ $moment(item.date_inspection).format('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="primary" @click="allowSecurityExit(item)">
                                    {{$t('allow_departure')}}
                                </v-btn>
                            </template>
                            <span>{{$t('allow_departure')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    name: "SecurityExits",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            security_exits: [],
            security_exitItems: [],
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('entry_exit'),
                    align: "center",
                    sortable: false,
                    value: "entry_exit"
                },
                {
                    text: this.$t('type_receipt'),
                    align: "center",
                    sortable: false,
                    value: "type_receipt"
                },
                {
                    text: this.$t('container_сar_number'),
                    align: "center",
                    sortable: false,
                    value: "container"
                },

                // {
                //     text: this.$t('contract'),
                //     align: "center",
                //     sortable: false,
                //     value: "contract"
                // },
                {
                    text: this.$t('allow_entry'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            container: null,
        };
    },
    computed: {
        ...mapGetters(['lang']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    watch: {
        options: {
            handler() {
                this.getSecurityExits()
            },
            deep: false
        }
    },
    methods: {
        async allowSecurityExit(item) {
            if (confirm(this.$t('are_you_sure_you_want_allow_departure'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .put(`partner/security_exit/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('allow_departure_has_been_updated'))
                        this.getSecurityExits()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('allow_departure_not_has_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getSecurityExits() {
            this.loading = true;
            let params = {};
            if (this.container) {
                params.container = this.container
            }
            if (this.dateFrom) {
                params.dateFrom = this.dateFrom
            }
            if (this.dateTo) {
                params.dateTo = this.dateTo
            }
            await this.$http
                .get("partner/security_exit", {
                    params: params,
                })
                .then(res => {
                    this.security_exits = res.body.data
                })
                .catch(err => {
                    this.security_exits = []
                    this.$toastr.error(this.$t('failed_to_get_list_allow_departures'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
